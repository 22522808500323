import { SpecialProductDimension } from './../special-product/special-product-dimension';
import { bindable } from 'aurelia-framework';
import { autoinject } from 'aurelia-framework';
import { Models } from 'models/core';
import { NettingService } from 'services/netting-service';
import { ErrorService } from 'services/error-service';
@autoinject
export class ServiceSidebarBasic {

  @bindable private net: Models.Net;
  @bindable private netDimension: Models.NetDimension;
  @bindable private specialProductDimension: Models.SpecialProductDimension;
  @bindable private service: Models.Service;
  
  constructor(
    private errorService: ErrorService,
    private nettingService: NettingService
  ) { }
}
